import { render, staticRenderFns } from "./AmpAuth.vue?vue&type=template&id=49f4764f&scoped=true"
import script from "./AmpAuth.vue?vue&type=script&lang=js"
export * from "./AmpAuth.vue?vue&type=script&lang=js"
import style0 from "./AmpAuth.vue?vue&type=style&index=0&id=49f4764f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f4764f",
  null
  
)

export default component.exports