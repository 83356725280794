<template>
  <div class="addColumnButton">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      nudge-left="50"
      nudge-bottom="50"
      left
      bottom
      min-width="400"
      class="ma-3"
      no-action
    >
      <template v-slot:activator="{ on }">
        <v-icon v-if="!largeIcon && columnLength < 30" size="13" color="success" v-on="on" class="column-actions__add">mdi-plus</v-icon>
        <v-icon v-else-if="!largeIcon && columnLength === 30" size="13" color="disabled" disabled v-on="on">mdi-plus</v-icon>
        <v-btn v-if="largeIcon" class="mx-2" fab large color="blue accent-3"  v-on="on" style="">
          <v-icon x-large color="white">mdi-arrow-bottom-left</v-icon>
          <v-icon size="13" class="mr-n4 ml-n2" color="white">mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card v-if="columnLength < 30" class="addColumnMenu" v-moveable>
        <v-toolbar color="primary" dark dense>
          <div style="width:100%;">
            <v-toolbar-title class="float-left"> Add Column </v-toolbar-title>
            <v-btn class="float-right cancelButton" icon small @click="menu = false" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list>
          <v-list-group
            v-for="(group, index) in menus"
            :key="index"
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="body-2"><v-icon class="mr-1">{{ group.icon }}</v-icon> {{ group.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="(item, itemIndex) in group.items">
              <component
                :is="item.component"
                :addColumn="addColumn"
                :flucs="group.title === 'Flucs'"
                v-if="item.show"
                :key="`comp-${itemIndex}`"
              />
              <v-list-item v-if="item.show && item.button" :key="`item-${itemIndex}`">
                <v-chip label color="blue" dark small>{{ item.button }}</v-chip>
                <v-list-item-content>
                </v-list-item-content>
                <v-btn x-small @click="item.action" class="bestPriceButton">Add</v-btn>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import PriceMenu from './PriceMenu'
import SpacerMenu from './SpacerMenu'
import RunnerInfoMenu from './RunnerInfoMenu'
import BetfairMenu from './BetfairMenu'
import CustomDataMenu from './CustomDataMenu'
import RatingsColumns from './RatingsColumns'
import { BOOKMAKER_CODE_NAMES, COLUMN_TYPE_BESTPRICE, COLUMN_TYPE_PRICE } from '../../common/constants'

export default {
  data: () => ({
    menu: false,
    menuGroups: [
      {
        title: 'Price',
        icon: 'mdi-cash-usd-outline',
        items: [
          { component: PriceMenu, show: true },
          { button: 'Best Odds', action: 'addBestPriceColumn', show: true },
          { button: 'Best Tote', action: 'addBestToteColumn', show: true },
          { button: '%OCC', action: 'addOCCColumn', show: true }
        ]
      },
      {
        title: 'Betfair',
        icon: 'mdi-chart-areaspline-variant',
        items: [
          { component: BetfairMenu, show: true },
          { button: 'BetFair Flucs', action: 'handleBfFlucs', show: true }
        ]
      },
      {
        title: 'Flucs',
        icon: 'mdi-chart-line-variant',
        items: [
          { component: PriceMenu, show: true }
        ]
      },
      {
        title: 'Spacer',
        icon: 'mdi-pan-horizontal',
        items: [
          { component: SpacerMenu, show: true }
        ]
      },
      {
        title: 'Runner Info',
        icon: 'mdi-information-outline',
        items: [
          { component: RunnerInfoMenu, show: true },
          { component: RatingsColumns, show: true }
        ]
      },
      {
        title: 'Custom Data',
        icon: 'mdi-pen',
        items: [
          { component: CustomDataMenu, show: true }
        ]
      }
    ],
    USMenuGroups: [
      {
        title: 'Price',
        icon: 'mdi-cash-usd-outline',
        items: [
          { component: PriceMenu, show: true }
        ]
      },
      {
        title: 'Flucs',
        icon: 'mdi-chart-line-variant',
        items: [
          { component: PriceMenu, show: true }
        ]
      },
      {
        title: 'Spacer',
        icon: 'mdi-pan-horizontal',
        items: [
          { component: SpacerMenu, show: true }
        ]
      },
      {
        title: 'Runner Info',
        icon: 'mdi-information-outline',
        items: [
          { component: RunnerInfoMenu, show: true },
          { component: RatingsColumns, show: true }
        ]
      },
      {
        title: 'Custom Data',
        icon: 'mdi-pen',
        items: [
          { component: CustomDataMenu, show: true }
        ]
      }
    ]
  }),
  components: {
    PriceMenu,
    SpacerMenu,
    RunnerInfoMenu,
    BetfairMenu,
    CustomDataMenu,
    RatingsColumns
  },
  props: {
    position: Number,
    largeIcon: Boolean,
    columnLength: Number
  },
  computed: {
    ...mapGetters(['getSelectedColumns', 'getSelectedColumnsName', 'hasFeature']),
    isDynamicOdds: function () {
      return process?.env?.VUE_APP_PRODUCT === 'DYNAMICODDS'
    },
    menus: function () {
      return this.isDynamicOdds ? this.menuGroups : this.USMenuGroups
    }
  },
  methods: {
    ...mapActions(['updateSelectedColumns']),
    handleBfFlucs: function () {
      const column = { source: 'BF', type: 'WIN', display: 1 }
      this.addColumn(column)
    },
    addColumn: function (column) {
      this.menu = !this.menuToggle
      const columns = [ ...this.getSelectedColumns ]
      const p = this.position < 0 ? columns.length + 1 : this.position
      columns.splice(p, 0, column)
      this.updateSelectedColumns({ name: this.getSelectedColumnsName, columns })
    },
    addBestPriceColumn: function () {
      const column = { source: 'BPC', type: 'BEST_PRICE', title: 'Best Odds', display: COLUMN_TYPE_BESTPRICE, filters: ['WIN_FIXED_ODDS'] }
      this.addColumn(column)
    },
    addBestToteColumn: function () {
      const column = { source: 'BTC', type: 'BEST_TOTE', title: 'Best Tote', display: COLUMN_TYPE_PRICE }
      this.addColumn(column)
    },
    addOCCColumn: function () {
      const column = { source: 'OCC', type: 'bookieSelect', title: '%OCC', display: COLUMN_TYPE_PRICE, filters: { value: BOOKMAKER_CODE_NAMES['Sportsbet'] } }
      this.addColumn(column)
    }
  }
}
</script>

<style>
  .addColumnButton {
    display: inline-block;
  }
  .draggable {
    cursor: grab;
  }
  .dragging {
    cursor: grabbing;
  }
</style>
