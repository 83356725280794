<template>
  <div class="duplicateColumnButton">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      nudge-left="50"
      nudge-bottom="60"
      left
      bottom
      min-width="400"
      class="ma-3"
      no-action
    >
      <template v-slot:activator="{ on }">
        <v-btn v-if="editMode" class="mx-2" color="orange accent-3" dark v-on="on" elevation="24">
          <v-icon color="white">mdi-content-duplicate</v-icon>
        </v-btn>
        <v-btn v-else x-small class="mx-2 ml-4" color="orange accent-3" dark v-on="on">
          <v-icon color="white" x-small>mdi-content-duplicate</v-icon>
        </v-btn>
      </template>

      <v-card class="duplicateColumnsList">
        <v-toolbar color="primary" dark dense>

          <v-toolbar-title>Duplicate Layout</v-toolbar-title>

          <v-spacer></v-spacer>

        </v-toolbar>

        <v-list class="duplicateColumnsList-content">
            <v-list-item-title class="ml-5 mr-2 d-inline">Copy layout '{{getSelectedColumnsName}}' to:</v-list-item-title>
            <v-btn x-small class="ml-2 d-inline duplicateLayoutButton" :disabled="depressed('A')" @click="duplicateLayout('A')">A</v-btn>
            <v-btn x-small class="ml-2 d-inline duplicateLayoutButton" :disabled="depressed('B')" @click="duplicateLayout('B')">B</v-btn>
            <v-btn x-small class="ml-2 d-inline duplicateLayoutButton" :disabled="depressed('C')" @click="duplicateLayout('C')">C</v-btn>
            <v-btn x-small class="ml-2 d-inline duplicateLayoutButton" :disabled="depressed('D')" @click="duplicateLayout('D')">D</v-btn>
            <v-btn x-small class="ml-2 d-inline duplicateLayoutButton" :disabled="depressed('E')" @click="duplicateLayout('E')">E</v-btn>
            <v-list-item-subtitle style="color:red" class="pa-5"><v-icon color="error">mdi-alert</v-icon> Changes are immeadiate and will overwrite existing layout</v-list-item-subtitle>
        </v-list>
        <v-list>
            <v-list-item-title class="ml-5 mr-2 d-inline">Set default to custom layout:</v-list-item-title>
            <template v-if="isDynamicOdds">
              <v-btn x-small class="ml-2 d-inline" @click="duplicateSetAuLayout()">AU</v-btn>
              <v-btn x-small class="ml-2 d-inline" @click="duplicateSetUkLayout()">UK</v-btn>
            </template>
            <template v-else>
              <v-btn x-small class="ml-2 d-inline" @click="duplicateSetUsLayout()">US</v-btn>
            </template>
            <v-list-item-subtitle style="color:red" class="pa-5"><v-icon color="error">mdi-alert</v-icon> This will overwrite your changes on custom layout '{{getSelectedColumnsName}}'</v-list-item-subtitle>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="menu = false" class="cancelButton" color="primary">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="loadDuplicate"
      hide-overlay
      persistent
      width="300"
      class="successDuplicate"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="duplicateSuccess"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline" style="color:#4CAF50">Duplicating Layout Successful!</v-card-title>

        <v-card-text v-if="editMode" style="color:red; font-weight:700;">
          You are now editing layout {{getSelectedColumnsName}}
        </v-card-text>
        <v-card-text v-else style="color:red; font-weight:700;">
          You are now using layout {{getSelectedColumnsName}}
        </v-card-text>

        <v-card-actions class="successDuplicateOk">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="duplicateSuccess = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import { AU_COLUMNS, UK_COLUMNS, US_COLUMNS } from '../../common/constants'
export default {
  data: () => ({
    menu: false,
    duplicateSuccess: false,
    loadDuplicate: false,
    setAuColumns: AU_COLUMNS,
    setUkColumns: UK_COLUMNS,
    setUsColumns: US_COLUMNS
  }),
  components: {
  },
  props: {
    position: Number,
    largeIcon: Boolean,
    editMode: Boolean
  },
  computed: {
    ...mapGetters(['getSelectedColumns', 'getSelectedColumnsName', 'hasFeature']),
    ...mapState(['selectedColumns']),
    isDynamicOdds () {
      return process?.env?.VUE_APP_PRODUCT === 'DYNAMICODDS'
    }
  },
  methods: {
    ...mapActions(['updateSelectedColumns']),
    duplicateLayout: function (name) {
      this.loadDuplicate = true
      this.updateSelectedColumns({ columns: this.getSelectedColumns, name })
      setTimeout(() => {
        this.loadDuplicate = false
        this.duplicateSuccess = true
      }, 800)
    },
    duplicateSetAuLayout: function () {
      this.loadDuplicate = true
      this.updateSelectedColumns({ columns: this.setAuColumns, name: this.getSelectedColumnsName })
      setTimeout(() => {
        this.loadDuplicate = false
        this.duplicateSuccess = true
      }, 800)
    },
    duplicateSetUkLayout: function () {
      this.loadDuplicate = true
      this.updateSelectedColumns({ columns: this.setUkColumns, name: this.getSelectedColumnsName })
      setTimeout(() => {
        this.loadDuplicate = false
        this.duplicateSuccess = true
      }, 800)
    },
    duplicateSetUsLayout: function () {
      this.loadDuplicate = true
      this.updateSelectedColumns({ columns: this.setUsColumns, name: this.getSelectedColumnsName })
      setTimeout(() => {
        this.loadDuplicate = false
        this.duplicateSuccess = true
      }, 800)
    },
    depressed: function (value) {
      if (value === this.getSelectedColumnsName) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
  .duplicateColumnButton {
    display: inline-block;
  }
  .draggable {
    cursor: grab;
  }
  .dragging {
    cursor: grabbing;
  }
</style>
