import axios from 'axios'
import { DO_SUBSCRIPTION_PRODUCTCODE, DF_SUBSCRIPTION_PRODUCTCODE } from './../common/constants'

export const getUserSubStatus = async function (config) {
  try {
    // check with new subscription service first
    var userSubInfo = {}
    if (window.doApp.$store.getters.hasFeature('newSubscription')) {
      // this will check
      userSubInfo = await getUserNewSubscriptionInfo(DO_SUBSCRIPTION_PRODUCTCODE) // {"email":"francis.shu.ms@outlook.com", "sublevelName":"DO + Form Analyser", "code":"SUBSCRIPTION_STANDARD_WITH_DYNAMICFORM", "subStatus":1, "subscriptionId":279, "subscriptionType":"RECURRING", "paymentType":"creditcard"}
      await window.doApp.$store.commit('account/setNewSubscriptionInfo', userSubInfo)
    }
    // no subscription found in new system and legacy sub not disabled yet
    // or new sub not enabled yet, check legacy sub
    // if (!userSubInfo.subStatus && window.doApp.$store.getters.hasFeature('legacySubscription')) {
    //   // check sub in legacy system
    //   // {"subscription_level":7}
    //   const result = await getUserSubStatusLegacy(config)
    //   const foundLevel = DO_SUBSCRIPTION_LEVELS.find(l => l.value === result.data?.subscription_level)
    //   userSubInfo = foundLevel ? { subStatus: 1, code: foundLevel.code } : {}
    // }
  } catch (e) {
    // well both system failed
  }
  // no sub in either system, prompt user to subscribe
  if (!userSubInfo?.subStatus) {
    await window.doApp.$store.dispatch('account/updateAuthToken')
    const token = window.localStorage.getItem('auth_token')
    const paramObj = {
      userToken: token,
      email: window.doApp.$store.getters['account/getUser'].attributes.email,
      productCode: DO_SUBSCRIPTION_PRODUCTCODE,
      memberId: window.doApp.$store.getters['account/getMemberId'],
      userName: window.doApp.$store.getters['account/getUsername'],
      isUpdatingCreditCard: false,
      callbackObj: {
        success: () => { window.location.reload() },
        cancel: () => { window.doApp.$store.dispatch('account/logout') },
        cancelText: 'SIGNOUT'
      }
    }
    window.__tbm__widget__sub.exposed.bootsTrap(paramObj)
  }
  return userSubInfo
}

export const getUserDFSubStatus = async function (config) {
  let hasDFSub = false
  // check if has DF sub in new system, yes then open DF
  if (this.hasFeature('newSubscription')) {
    const userSubInfo = await getUserNewSubscriptionInfo(DF_SUBSCRIPTION_PRODUCTCODE)
    hasDFSub = userSubInfo.subStatus === 1
  }
  // no sub in new system, then check legacy is legacy still enabled
  // if (!hasDFSub && this.hasFeature('legacySubscription')) {
  //   const legacySub = await getUserSubStatusLegacy(window.doApp.$store.getters.authConfig)
  //   hasDFSub = legacySub?.data?.subscription_level === 7
  // }
  return hasDFSub
}

export const getUserSubStatusLegacy = async function (config) {
  const res = await axios.post('public/betting/getSubscription', {
    source: 'DO'
  }, config)
   return res
}

export const getUserNewSubscriptionInfo = async function (productCode) {
  // ensure token is up to date
  await window.doApp.$store.dispatch('account/updateAuthToken')
  const token = window.localStorage.getItem('auth_token')
  const memberId = window.localStorage.getItem('memberId')
  if (memberId) {
    try {
      const userSubInfo = await window.__tbm__widget__sub.exposed.checkUserSubscriptionInfo(token, window.doApp.$store.getters['account/getUser'].attributes.email, productCode, window.doApp.$store.getters['account/getUsername'], memberId)
      return userSubInfo
    } catch (error) {
      return {}
    }
  }
}

export const updateCreditCard = async function () {
  await window.doApp.$store.dispatch('account/updateAuthToken')
  const token = window.localStorage.getItem('auth_token')
  const paramObj = {
    userToken: token,
    email: window.doApp.$store.getters['account/getUser'].attributes.email,
    productCode: DO_SUBSCRIPTION_PRODUCTCODE,
    memberId: window.doApp.$store.getters['account/getMemberId'],
    userName: window.doApp.$store.getters['account/getUsername'],
    isUpdatingCreditCard: true
  }
  window.__tbm__widget__sub.exposed.bootsTrap(paramObj)
}
