export const BET_TYPES = {
  PLACE_FIXED_ODDS: 'PLACE_FIXED_ODDS',
  WIN_FIXED_ODDS: 'WIN_FIXED_ODDS',
  WIN: 'WIN',
  PLACE: 'PLACE',
  SHOW: 'PLACE_2',
  WIN_FIXED_ODDS_W: 'W',
  PLACE_FIXED_ODDS_P: 'P'
}

/* Bookmaker Names */
export const BOOKMAKER_NAMES_LOOKUP = {
  SB2: 'Sportsbet',
  BF: 'Betfair',
  LB: 'LuxBet',
  SB5: 'BoomBet',
  N: 'TAB NSW',
  TS_N: 'TAB Fixed NSW',
  V: 'TAB VIC',
  TS_V: 'TAB Fixed VIC',
  Q: 'UBET',
  TS_Q: 'TAB Fixed QLD',
  NZ: 'NZ TAB',
  BE: 'BetEasy',
  CB: 'Centrebet',
  // BB2: 'BestBookies',
  TS2: 'TopSport',
  BT: 'Bet365',
  OB: 'TABTouch',
  WH: 'William Hill UK',
  LB2: 'Ladbrokes',
  LB2U: 'Ladbrokes UK',
  UB: 'Unibet',
  CB2: 'PlayUp',
  TB2: 'TopBetta',
  OP: 'Official Price',
  BV: 'BetVictor',
  SB6: 'SkyBet',
  CB3: 'Coral',
  BS3: 'BoyleSports',
  RB2: 'RaceBets',
  STT: 'Global Tote',
  ND: 'Neds',
  BB3: 'BlueBet',
  BB4: 'BBet',
  BD: 'Betdogs',
  RB3: 'Real Bookie',
  // BD2: 'Bet Deluxe',
  DD: 'David Dwyer',
  PP2: 'Paddy Power',
  SBU: 'Sportsbet UK',
  UB_U: 'Unibet UK',
  '888': '888 Sport',
  BW2: 'Betway',
  WB3: 'WinBet',
  CHB: 'ChaseBet',
  BB: 'BaggyBet',
  SCR: 'SportChamp Racing',
  ESB: 'EskanderBet',
  GSB: 'Get Set Bet',
  VPB: 'VIP Betting',
  BG: 'Bet Gold'
  // '10B': '10 Bet'
  // SB: 'William Hillevo
}

export const BOOKMAKER_CODE_NAMES = {
  'Sportsbet': 'SB2',
  'LuxBet': 'LB',
  'BoomBet': 'SB5',
  'TAB NSW': 'N',
  'TAB Fixed NSW': 'TS_N',
  'TAB Fixed VIC': 'TS_V',
  'TAB VIC': 'V',
  'UBET': 'Q',
  'TAB Fixed QLD': 'TS_Q',
  'BetEasy': 'BE',
  'Centrebet': 'CB',
  'Get Set Bet': 'GSB',
  // 'BestBookies': 'BB2',
  'TopSport': 'TS2',
  'Bet365': 'BT',
  'TABTouch': 'OB',
  'Ladbrokes': 'LB2',
  'Ladbrokes UK': 'LB2U',
  'Unibet': 'UB',
  'PlayUp': 'CB2',
  'TopBetta': 'TB2',
  'Official Price': 'OP',
  'Global Tote': 'STT',
  'Neds': 'ND',
  'BlueBet': 'BB3',
  'BBet': 'BB4',
  'Betdogs': 'BD',
  'Real Bookie': 'RB3',
  // 'Bet Deluxe': 'BD2',
  'David Dwyer': 'DD',
  'Betfair': 'BF',
  'NZ TAB': 'NZ',
  'BetVictor': 'BV',
  'SkyBet': 'SB6',
  'Coral': 'CB3',
  'BoyleSports': 'BS3',
  'RaceBets': 'RB2',
  'William Hill UK': 'WH',
  'Paddy Power': 'PP2',
  'Sportsbet UK': 'SBU',
  'Unibet UK': 'UB_U',
  '888 Sport': '888',
  'Betway': 'BW2',
  'WinBet': 'WB3',
  'ChaseBet': 'CHB',
  'VIP Betting': 'VPB',
  'Bet Gold': 'BG',
  'BaggyBet': 'BB',
  'SportChamp Racing': 'SCR',
  'EskanderBet': 'ESB'
}

/* State Codes */
export const COUNTRY_CODE_LOOKUP = {
  NT: 'Darwin',
  QLD: 'Queensland',
  NSW: 'Sydney',
  VIC: 'Victoria',
  TAS: 'Hobart',
  WA: 'Perth',
  SA: 'Adelaide',
  ACT: 'Canberra'
}

/* Column Types */
export const COLUMN_TYPE_PRICE = 0
export const COLUMN_TYPE_FLUCS = 1
export const COLUMN_TYPE_INFO = 2
export const COLUMN_TYPE_BESTPRICE = 3

/* Result Status */
export const RESULT_STATUS_INTERIM = 'Interim'
export const RESULT_STATUS_FINAL = 'Final'
export const RESULT_STATUS_PARTIAL_PAY = 'Partial Pay'
export const RESULT_STATUS_PROTEST = 'Protest'
export const RESULT_STATUS_PENDING = 'Pending'
export const RESULT_STATUS_RERESULT = 'Re-result'
export const RESULT_STATUS_ABANDONED = 'Abandoned'

export const RESULT_STATUS_LOOKUP = {
  0: RESULT_STATUS_INTERIM,
  1: RESULT_STATUS_FINAL,
  2: RESULT_STATUS_PARTIAL_PAY,
  3: RESULT_STATUS_PROTEST,
  4: RESULT_STATUS_PENDING,
  5: RESULT_STATUS_RERESULT,
  6: RESULT_STATUS_ABANDONED
}

/* Race Status */
export const RACE_STATUS_UNKNOWN = 'Unknown'
export const RACE_STATUS_OPEN = 'Open'
export const RACE_STATUS_CLOSED = 'Closed'
export const RACE_STATUS_INTERIM = 'Interim'
export const RACE_STATUS_FINAL = 'Final'
export const RACE_STATUS_INPROGESS = 'In Progress'
export const RACE_STATUS_BEHINDGATES = 'Behind Gates'
export const RACE_STATUS_PASTPOST = 'Past Post'
export const RACE_STATUS_ABANDONED = 'Abandoned'
export const RACE_STATUS_PROTEST = 'Pro Test'

export const RACE_STATUS_LOOKUP = {
  0: RACE_STATUS_UNKNOWN,
  1: RACE_STATUS_OPEN,
  2: RACE_STATUS_CLOSED,
  3: RACE_STATUS_INTERIM,
  4: RACE_STATUS_FINAL,
  5: RACE_STATUS_INPROGESS,
  6: RACE_STATUS_BEHINDGATES,
  7: RACE_STATUS_PASTPOST,
  8: RACE_STATUS_ABANDONED,
  9: RACE_STATUS_PROTEST
}

/* Price Types */
export const BETTYPE_WIN = 'Tote'
export const BETTYPE_WINFIXEDODDS = 'Fixed'
export const BETTYPE_PLACE = 'Tote P'
export const BETTYPE_SHOW = 'Tote S'
export const BETTYPE_PLACEFIXED = 'Fixed P'

export const BETTYPE = {
  WIN: BETTYPE_WIN,
  WIN_FIXED_ODDS: BETTYPE_WINFIXEDODDS,
  PLACE: BETTYPE_PLACE,
  PLACE_FIXED_ODDS: BETTYPE_PLACEFIXED,
  PLACE_2: BETTYPE_SHOW,
  'back-1': 'BWO1',
  'back-2': 'BWO2',
  'back-3': 'BWO3',
  'back-amount-1': 'BWA1',
  'back-amount-2': 'BWA2',
  'back-amount-3': 'BWA3',
  'lay-1': 'LWO1',
  'lay-2': 'LWO2',
  'lay-3': 'LWO3',
  'lay-amount-1': 'LWA1',
  'lay-amount-2': 'LWA2',
  'lay-amount-3': 'LWA3',
  'last-matched-price': 'LMP',
  'projected-sp': 'BF SP',
  'total-matched': 'Tot M W',
  'weighted-avg': 'WAP'
}

export const BETFAIR_PLACE = {
  'back-1': 'BPO1',
  'back-2': 'BPO2',
  'back-3': 'BPO3',
  'back-amount-1': 'BPA1',
  'back-amount-2': 'BPA2',
  'back-amount-3': 'BPA3',
  'lay-1': 'LPO1',
  'lay-2': 'LPO2',
  'lay-3': 'LPO3',
  'lay-amount-1': 'LPA1',
  'lay-amount-2': 'LPA2',
  'lay-amount-3': 'LPA3',
  'last-matched-price': 'LMPP',
  'total-matched': 'Tot M P'
}

export const NON_BETTING_COLUMN = {
  'back-2': true,
  'back-3': true,
  'back-amount-1': true,
  'back-amount-2': true,
  'back-amount-3': true,
  'lay-2': true,
  'lay-3': true,
  'lay-amount-1': true,
  'lay-amount-2': true,
  'lay-amount-3': true,
  'last-matched-price': true,
  'total-matched': true,
  'weighted-avg': true
}

export const SHOW_MARKET_PERCENTAGE = {
  WIN: true,
  WIN_FIXED_ODDS: true,
  PLACE: true,
  PLACE_FIXED_ODDS: true,
  SHOW: true,
  'back-1': true,
  'back-2': true,
  'back-3': true,
  'lay-1': true,
  'lay-2': true,
  'lay-3': true,
  'last-matched-price': true,
  'projected-sp': true,
  'weighted-avg': true
}

export const SHOW_PRICE_UPDATE = {
  WIN: true,
  WIN_FIXED_ODDS: true,
  PLACE: true,
  PLACE_FIXED_ODDS: true,
  SHOW: true,
  PLACE_2: true,
  'last-matched-price': true
}

/* Scratching Types */
export const STARTER = 'Starter'
export const SCRATCHED = 'Scratched'
export const LATESCRATCHED = 'Late Scratched'

export const STATUSINDICATOR_VALUE = {
  0: STARTER,
  1: SCRATCHED,
  2: LATESCRATCHED
}

/* TrackCondition Types */
export const TRACKCONDITION_TRANSLATION = {
  UNKNOWN: 'Unkown',
  UNKNOWN_CONDITION: 'Unknown',
  FIRM1: 'Firm 1',
  FIRM2: 'Firm 2',
  GOOD3: 'Good 3',
  GOOD4: 'Good 4',
  SOFT5: 'Soft 5',
  SOFT6: 'Soft 6',
  SOFT7: 'Soft 7',
  HEAVY8: 'Heavy 8',
  HEAVY9: 'Heavy 9',
  HEAVY10: 'Heavy 10',
  DEAD: 'Dead',
  DIRT: 'Dirt',
  EASY: 'Easy',
  FAIR: 'Fair',
  FAST: 'Fast',
  GOOD: 'Good',
  HEAVY: 'Heavy',
  SAND: 'Sand',
  SLOW: 'Slow',
  VERYHEAVY: 'Very Heavy',
  FIRM: 'Firm',
  SOFT: 'Soft'
}

// export const TRACKCONDITION = {
//   0: UNKNOWN,
//   1: FIRM1,
//   2: FIRM2,
//   3: GOOD3,
//   4: GOOD4,
//   5: SOFT5,
//   6: SOFT6,
//   7: SOFT7,
//   8: HEAVY8,
//   9: HEAVY9,
//   10: HEAVY10,
//   11: DEAD,
//   12: DIRT,
//   13: EASY,
//   14: FAIR,
//   15: FAST,
//   16: GOOD,
//   17: HEAVY,
//   18: SAND,
//   19: SLOW,
//   20: VERYHEAVY,
//   21: FIRM,
//   22: SOFT
// }

export const TRACKCONDITION = {
  UNKNOWN_CONDITION: null,
  FIRM1: 'Fast',
  FIRM2: 'Fast',
  GOOD3: 'Good',
  GOOD4: 'Good',
  SOFT5: 'Dead',
  SOFT6: 'Slow',
  SOFT7: 'Slow',
  HEAVY8: 'Heavy',
  HEAVY9: 'Heavy',
  HEAVY10: 'Heavy',
  DEAD: 'Dead',
  DIRT: 'Dead',
  EASY: 'Dead',
  FAIR: 'Good',
  FAST: 'Fast',
  GOOD: 'Good',
  HEAVY: 'Heavy',
  SAND: 'Slow',
  SLOW: 'Slow',
  VERY_HEAVY: 'Heavy',
  FIRM: 'Good',
  SOFT: 'Slow'
}

export const RACESTATUS = {
  UNKNOWN: true,
  OPEN: 'Open',
  CLOSED: 'Closed',
  INTERIM: 'Interim/Paying',
  FINAL: 'Interim/Paying',
  IN_PROGRESS: 'Closed',
  BEHIND_GATES: 'Open',
  PAST_POST: 'Closed',
  ABANDONED: 'Abandoned',
  PROTEST: 'Closed'
}

/* Meeting Types */
export const MEETING_TYPE_GALLOPS = 'HorseRacing'
export const MEETING_TYPE_GREYHOUNDS = 'GreyHoundRacing'
export const MEETING_TYPE_HARNESS = 'HarnessRacing'

/* Track Direction */
export const TRACKDIRECTION = {
  'AUS/QLD': 'clockwise',
  'AUS/NSW': 'clockwise',
  'AUS/VIC': 'anti-clockwise',
  'AUS/SA': 'anti-clockwise',
  'AUS/TAS': 'anit-clockwise',
  'AUS/WA': 'anti-clockwise'
}

export const SPEEDMAP_LABELS = {
  LEAD: 0,
  'LEAD/HANDY': 2,
  HANDY: 4,
  'HANDY/MID': 6,
  MID: 8,
  REAR: 10
}

export const RACE_TYPE_IMAGE = {
  HarnessRacing: '/img/race_types/DO_RaceType_Harness-BLACK.svg',
  GreyHoundRacing: '/img/race_types/DO_RaceType_Greyhounds-BLACK.svg',
  HorseRacing: '/img/race_types/DO_RaceType_Gallops-BLACK.svg',
  HARNESS: '/img/race_types/DO_RaceType_Harness-BLACK.svg',
  GREYHOUND: '/img/race_types/DO_RaceType_Greyhounds-BLACK.svg',
  THOROUGHBRED: '/img/race_types/DO_RaceType_Gallops-BLACK.svg'
}

// convert bet type from betting API to coredb type

export const CONVERT_BET_TYPE = {
  Fixed_W: BET_TYPES.WIN_FIXED_ODDS,
  Fixed_P: BET_TYPES.PLACE_FIXED_ODDS,
  Tote_W: BET_TYPES.WIN,
  Tote_P: BET_TYPES.PLACE,
  Tote_S: BET_TYPES.SHOW
}
// enum BetType {
//   ALL
//   DAILY_DOUBLE
//   EARLY_QUADRELLA
//   EXACTA
//   EXTRA_DOUBLE
//   FIRST_FOUR
//   OPENING_FLUC
//   PLACE
//   PLACE_FIXED_ODDS
//   QUADRELLA
//   QUINELLA
//   QUINELLA_FIXED_ODDS
//   QUINELLA_PLACE
//   RUNNING_DOUBLE
//   SIX_UP
//   STARTING_PRICE
//   TOP_FLUC
//   TREBLE
//   TRIFECTA
//   TRIO
//   WIN
//   WIN_FIXED_ODDS
// }

export const BETSTATUS = {
  NOT_PROCESSED: 'Bet not processed',
  PRICE_CHANGED: 'Price changed',
  PENDING_APPROVAL: 'Bet pending approval',
  BET_REJECTED: 'Bet Rejected',
  BET_FAILED: 'Bet Failed',
  BETTING_SUSPENDED: 'Betting suspended',
  UNKNOWN: 'Unknown error',
  SESSION_EXPIRED: 'Session expired',
  INSUFFICIENT_FUNDS: 'Insufficient Funds',
  BET_PLACED: 'Bet placed',
  PARTIALLY_ACCEPTED: 'Bet partially accepted',
  BET_TYPE_SUSPENDED: 'Bet type suspended',
  UNMATCHED: 'Unmatched',
  PARTIALLY_MATCHED: 'Partially matched',
  PLACED_AT_LOWER_PRICE: 'Placed at lower price',
  CANCELLED: 'Bet cancelled',
  LAPSED: 'Lapsed',
  BET_SENT: 'Bet sent',
  UNKNOWN_CHECK_STATEMENT: 'Check statement',
  BETTING_CLOSED: 'Betting closed'
}

export const BET_STATUS_SUCCESS = {
  NOT_PROCESSED: 'error',
  PRICE_CHANGED: 'error',
  PENDING_APPROVAL: 'warning',
  BET_REJECTED: 'error',
  BET_FAILED: 'error',
  BETTING_SUSPENDED: 'error',
  UNKNOWN: 'error',
  UNKNOWN_CHECK_STATEMENT: 'error',
  SESSION_EXPIRED: 'error',
  INSUFFICIENT_FUNDS: 'error',
  BET_PLACED: 'success',
  PARTIALLY_ACCEPTED: 'warning',
  BET_TYPE_SUSPENDED: 'error',
  UNMATCHED: 'error',
  PARTIALLY_MATCHED: 'warning',
  PLACED_AT_LOWER_PRICE: 'warning',
  CANCELLED: 'error',
  LAPSED: 'error',
  BET_SENT: 'success',
  BETTING_CLOSED: 'error'
}
// {"bet_id":23578248,"status_code":13,"status_text":"UNKNOWN_CHECK_STATEMENT","partial_stake":7.57,"agency_bet_id":"223301391279"}
export const BET_STATUS_CODE = {
  0: 'NOT_PROCESSED',
  1: 'PRICE_CHANGED',
  2: 'PENDING_APPROVAL',
  3: 'BET_REJECTED',
  4: 'BET_FAILED',
  5: 'BETTING_SUSPENDED',
  6: 'UNKNOWN',
  7: 'SESSION_EXPIRED',
  8: 'INSUFFICIENT_FUNDS',
  9: 'BET_PLACED',
  10: 'PARTIALLY_ACCEPTED',
  11: 'BET_TYPE_SUSPENDED',
  12: 'UNMATCHED',
  13: 'PARTIALLY_MATCHED',
  14: 'PLACED_AT_LOWER_PRICE',
  15: 'CANCELLED',
  16: 'LAPSED'
}

export const BET_STATUS_CODE_ENG = {
  0: 'Not Processed',
  1: 'Price Changed',
  2: 'Pending Approval',
  3: 'Bet Rejected',
  4: 'Bet Failed',
  5: 'Betting Suspended',
  6: 'Unknown',
  7: 'Session Expired',
  8: 'Insufficient Funds',
  9: 'Bet Placed',
  10: 'Partially Accepted',
  11: 'Bet Type Suspended',
  12: 'Unmatched Check Statement',
  13: 'Partially Matched',
  14: 'Placed At Lower Price',
  15: 'Cancelled',
  16: 'Lapsed'
}

export const BETFAIR_BET_TYPE_LOOKUP = {
  'back-1': 'Back Odds 1',
  'back-2': 'Back Odds 2',
  'back-3': 'Back Odds 3',
  'back-amount-1': 'Back Amount 1',
  'back-amount-2': 'Back Amount 2',
  'back-amount-3': 'Back Amount 3',
  'lay-1': 'Lay Odds 1',
  'lay-2': 'Lay Odds 2',
  'lay-3': 'Lay Odds 3',
  'lay-amount-1': 'Lay Amount 1',
  'lay-amount-2': 'Lay Amount 2',
  'lay-amount-3': 'Lay Amount 3',
  'last-matched-price': 'Last Matched Price',
  'projected-sp': 'Projected SP',
  'total-matched': 'Total Matched Amount',
  'weighted-avg': 'Weighted Avg Price'
}

export const SHOW_BEST_PRICE = {
  WIN_FIXED_ODDS: true,
  PLACE_FIXED_ODDS: true,
  'back-1': true
}
/* Bookmaker Web pages */
export const BOOKMAKER_WEB_PAGE = {
  SB2: 'https://www.sportsbet.com.au/',
  BF: 'https://www.betfair.com.au/exchange/plus/',
  LB: 'LuxBet',
  SB5: 'https://www.boombet.com.au/',
  N: 'https://www.tab.com.au/',
  TS_N: 'https://www.tab.com.au/',
  V: 'https://www.tab.com.au/',
  TS_V: 'https://www.tab.com.au/',
  Q: 'https://www.tab.com.au/',
  TS_Q: 'https://www.tab.com.au/',
  NZ: 'https://www.tab.co.nz/',
  BE: 'https://beteasy.com.au/login',
  CB: 'Centrebet',
  // BB2: 'https://www.bookies.com.au/v2/',
  TS2: 'https://www.topsport.com.au/',
  BT: 'https://www.bet365.com.au/#/HO/',
  OB: 'https://www.tabtouch.com.au/',
  WH: 'https://auth.williamhill.com/cas/login',
  LB2: 'https://www.ladbrokes.com.au/',
  LB2U: 'https://www.ladbrokes.co.uk/',
  UB: 'https://www.unibet.com.au/',
  CB2: 'https://www.playup.com.au/',
  TB2: 'https://www.topbetta.com.au/',
  OP: 'http://officialprice.com.au/',
  BV: 'https://www.betvictor.com/',
  SB6: 'https://m.skybet.com/',
  CB3: 'https://sports.coral.co.uk/',
  BS3: 'https://www.boylesports.com/',
  RB2: 'https://www.racebets.com/en/horse-racing/',
  STT: 'https://globaltote.bet/',
  ND: 'https://www.neds.com.au/',
  BB3: 'https://www.bluebet.com.au/',
  BB4: 'https://www.bbet.com.au/',
  BD: 'https://www.betdogs.com.au/',
  RB3: 'https://realbookie.com.au/home',
  DD: 'https://davedwyer.mytrackprice.com/',
  BD2: 'https://www.betdeluxe.com.au/home',
  PP2: 'https://www.paddypower.com/bet',
  SBU: 'https://sports.sportingbet.com/en/sports',
  '888': 'https://www.888sport.com',
  BW2: 'https://betway.com/',
  WB3: 'https://winbet.com.au/',
  BB: 'https://baggybet.com/',
  SCR: 'https://www.sportchamps.com.au/racebook',
  ESB: 'https://eskanderbet.com.au/',
  GSB: 'https://www.getsetbet.com.au/',
  VPB: 'https://vipbettingservices.com.au/',
  BG: 'https://betgold.com.au/',
  CHB: 'https://chasebet.com.au/'
  // '10B': 'https://www.10bet.com/sports/'
  // SB: 'William Hill',
}

/* Bookmaker Web pages */
export const BOOKMAKER_FLUC_TYPE = {
  SB2: BET_TYPES.WIN_FIXED_ODDS,
  BF: BET_TYPES.WIN,
  LB: BET_TYPES.WIN_FIXED_ODDS,
  SB5: BET_TYPES.WIN_FIXED_ODDS,
  N: BET_TYPES.WIN,
  TS_N: BET_TYPES.WIN_FIXED_ODDS,
  V: BET_TYPES.WIN,
  TS_V: BET_TYPES.WIN_FIXED_ODDS,
  Q: BET_TYPES.WIN,
  TS_Q: BET_TYPES.WIN_FIXED_ODDS,
  NZ: BET_TYPES.WIN_FIXED_ODDS,
  BE: BET_TYPES.WIN_FIXED_ODDS,
  CB: BET_TYPES.WIN_FIXED_ODDS,
  // BB2: BET_TYPES.WIN_FIXED_ODDS,
  TS2: BET_TYPES.WIN_FIXED_ODDS,
  BT: BET_TYPES.WIN_FIXED_ODDS,
  OB: BET_TYPES.WIN_FIXED_ODDS,
  WH: BET_TYPES.WIN_FIXED_ODDS,
  LB2: BET_TYPES.WIN_FIXED_ODDS,
  LB2U: BET_TYPES.WIN_FIXED_ODDS,
  UB: BET_TYPES.WIN_FIXED_ODDS,
  CB2: BET_TYPES.WIN_FIXED_ODDS,
  TB2: BET_TYPES.WIN_FIXED_ODDS,
  OP: BET_TYPES.WIN_FIXED_ODDS,
  BV: BET_TYPES.WIN_FIXED_ODDS,
  SB6: BET_TYPES.WIN_FIXED_ODDS,
  CB3: BET_TYPES.WIN_FIXED_ODDS,
  BS3: BET_TYPES.WIN_FIXED_ODDS,
  RB2: BET_TYPES.WIN_FIXED_ODDS,
  GT: BET_TYPES.WIN_FIXED_ODDS,
  ND: BET_TYPES.WIN_FIXED_ODDS,
  BB3: BET_TYPES.WIN_FIXED_ODDS,
  BB4: BET_TYPES.WIN_FIXED_ODDS,
  BD: BET_TYPES.WIN_FIXED_ODDS,
  RB3: BET_TYPES.WIN_FIXED_ODDS,
  DD: BET_TYPES.WIN_FIXED_ODDS,
  PP2: BET_TYPES.WIN_FIXED_ODDS,
  SBU: BET_TYPES.WIN_FIXED_ODDS,
  UB_U: BET_TYPES.WIN_FIXED_ODDS,
  '888': BET_TYPES.WIN_FIXED_ODDS,
  BW2: BET_TYPES.WIN_FIXED_ODDS,
  WB3: BET_TYPES.WIN_FIXED_ODDS,
  BB: BET_TYPES.WIN_FIXED_ODDS,
  SCR: BET_TYPES.WIN_FIXED_ODDS,
  ESB: BET_TYPES.WIN_FIXED_ODDS,
  GSB: BET_TYPES.WIN_FIXED_ODDS,
  VPB: BET_TYPES.WIN_FIXED_ODDS,
  BG: BET_TYPES.WIN_FIXED_ODDS,
  CHB: BET_TYPES.WIN_FIXED_ODDS
  // '10B': 'WIN_FIXED_ODDS'
  // SB: '',
}

export const ALL_BOOKIES_CODES = [
  { source: BOOKMAKER_CODE_NAMES['Sportsbet'] },
  { source: BOOKMAKER_CODE_NAMES['LuxBet'] },
  { source: BOOKMAKER_CODE_NAMES['BoomBet'] },
  { source: BOOKMAKER_CODE_NAMES['TAB NSW'] },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed NSW'] },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed VIC'] },
  { source: BOOKMAKER_CODE_NAMES['TAB VIC'] },
  { source: BOOKMAKER_CODE_NAMES['UBET'] },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed QLD'] },
  { source: BOOKMAKER_CODE_NAMES['BetEasy'] },
  // { source: BOOKMAKER_CODE_NAMES['BestBookies'] },
  // { source: BOOKMAKER_CODE_NAMES['BestBookies'] },
  { source: BOOKMAKER_CODE_NAMES['TopSport'] },
  { source: BOOKMAKER_CODE_NAMES['Bet365'] },
  { source: BOOKMAKER_CODE_NAMES['TABTouch'] },
  { source: BOOKMAKER_CODE_NAMES['Ladbrokes'] },
  { source: BOOKMAKER_CODE_NAMES['Ladbrokes UK'] },
  { source: BOOKMAKER_CODE_NAMES['Unibet'] },
  { source: BOOKMAKER_CODE_NAMES['PlayUp'] },
  { source: BOOKMAKER_CODE_NAMES['TopBetta'] },
  { source: BOOKMAKER_CODE_NAMES['Official Price'] },
  { source: BOOKMAKER_CODE_NAMES['Global Tote'] },
  { source: BOOKMAKER_CODE_NAMES['Neds'] },
  { source: BOOKMAKER_CODE_NAMES['BlueBet'] },
  { source: BOOKMAKER_CODE_NAMES['BBet'] },
  { source: BOOKMAKER_CODE_NAMES['Betdogs'] },
  { source: BOOKMAKER_CODE_NAMES['Real Bookie'] },
  // { source: BOOKMAKER_CODE_NAMES['Bet Deluxe'] },
  { source: BOOKMAKER_CODE_NAMES['David Dwyer'] },
  { source: BOOKMAKER_CODE_NAMES['Betfair'] },
  { source: BOOKMAKER_CODE_NAMES['NZ TAB'] },
  { source: BOOKMAKER_CODE_NAMES['BetVictor'] },
  { source: BOOKMAKER_CODE_NAMES['SkyBet'] },
  { source: BOOKMAKER_CODE_NAMES['Coral'] },
  { source: BOOKMAKER_CODE_NAMES['BoyleSports'] },
  { source: BOOKMAKER_CODE_NAMES['RaceBets'] },
  { source: BOOKMAKER_CODE_NAMES['William Hill UK'] },
  { source: BOOKMAKER_CODE_NAMES['Paddy Power'] },
  { source: BOOKMAKER_CODE_NAMES['Sportsbet UK'] },
  { source: BOOKMAKER_CODE_NAMES['Unibet UK'] },
  { source: BOOKMAKER_CODE_NAMES['888 Sport'] },
  { source: BOOKMAKER_CODE_NAMES['Betway'] },
  { source: BOOKMAKER_CODE_NAMES['WinBet'] },
  { source: BOOKMAKER_CODE_NAMES['BaggyBet'] },
  { source: BOOKMAKER_CODE_NAMES['SportChamp Racing'] },
  { source: BOOKMAKER_CODE_NAMES['EskanderBet'] },
  { source: BOOKMAKER_CODE_NAMES['Get Set Bet'] },
  { source: BOOKMAKER_CODE_NAMES['VIP Betting'] },
  { source: BOOKMAKER_CODE_NAMES['Bet Gold'] },
  { source: BOOKMAKER_CODE_NAMES['ChaseBet'] }
  // { source: '10B' }
]

export const PRICE_ALERT_BOOKIES = [
  { source: BOOKMAKER_CODE_NAMES['BaggyBet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Bet Gold'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['ChaseBet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['SportChamp Racing'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['EskanderBet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Betfair'], type: 'back-1' },
  { source: BOOKMAKER_CODE_NAMES['Betfair'], type: 'lay-1' },
  { source: BOOKMAKER_CODE_NAMES['Sportsbet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['BoomBet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed NSW'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed VIC'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed QLD'], type: BET_TYPES.WIN_FIXED_ODDS },
  // { source: BOOKMAKER_CODE_NAMES['BestBookies'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TopSport'], type: BET_TYPES.WIN_FIXED_ODDS },
  // { source: 'BT', type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Ladbrokes'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Unibet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['PlayUp'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Official Price'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Neds'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['BlueBet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Betdogs'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Real Bookie'], type: BET_TYPES.WIN_FIXED_ODDS },
  // { source: BOOKMAKER_CODE_NAMES['Bet Deluxe'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['David Dwyer'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['WinBet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TAB VIC'], type: BET_TYPES.WIN },
  { source: BOOKMAKER_CODE_NAMES['UBET'], type: BET_TYPES.WIN },
  { source: BOOKMAKER_CODE_NAMES['TAB NSW'], type: BET_TYPES.WIN },
  { source: BOOKMAKER_CODE_NAMES['Get Set Bet'], type: BET_TYPES.WIN_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['VIP Betting'], type: BET_TYPES.WIN_FIXED_ODDS }
]

export const PRICE_ALERT_BOOKIES_PLACE = [
  { source: BOOKMAKER_CODE_NAMES['BaggyBet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Bet Gold'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['SportChamp Racing'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['EskanderBet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Sportsbet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['BoomBet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed NSW'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed VIC'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed QLD'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Centrebet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['ChaseBet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  // { source: BOOKMAKER_CODE_NAMES['BestBookies'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TopSport'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Bet365'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Ladbrokes'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Unibet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['PlayUp'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Official Price'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Neds'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['BlueBet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Betdogs'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['Real Bookie'], type: BET_TYPES.PLACE_FIXED_ODDS },
  // { source: BOOKMAKER_CODE_NAMES['Bet Deluxe'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['David Dwyer'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['WinBet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['TAB VIC'], type: BET_TYPES.PLACE },
  { source: BOOKMAKER_CODE_NAMES['UBET'], type: BET_TYPES.PLACE },
  { source: BOOKMAKER_CODE_NAMES['TAB NSW'], type: BET_TYPES.PLACE },
  { source: BOOKMAKER_CODE_NAMES['Get Set Bet'], type: BET_TYPES.PLACE_FIXED_ODDS },
  { source: BOOKMAKER_CODE_NAMES['VIP Betting'], type: BET_TYPES.PLACE_FIXED_ODDS }
]

export const AU_COLUMNS = [
  { source: BOOKMAKER_CODE_NAMES['Sportsbet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_FLUCS },
  { source: BOOKMAKER_CODE_NAMES['Sportsbet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['TopSport'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed VIC'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed NSW'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed QLD'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  // { source: BOOKMAKER_CODE_NAMES['Bet Deluxe'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Ladbrokes'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Neds'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Unibet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['PlayUp'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  // { source: BOOKMAKER_CODE_NAMES['BestBookies'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['BoomBet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['BaggyBet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['David Dwyer'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Real Bookie'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  // { source: BOOKMAKER_CODE_NAMES['WinBet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE }, // This bookie no longer wants to be part of DynamicOdds
  { source: BOOKMAKER_CODE_NAMES['BlueBet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['BBet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Betdogs'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Get Set Bet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['VIP Betting'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Bet Gold'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['ChaseBet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: 'SPC' }, // Empty spacer
  { source: BOOKMAKER_CODE_NAMES['Betfair'], type: 'back-1', level: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Betfair'], type: 'lay-1', level: COLUMN_TYPE_PRICE },
  { source: 'SPC' }, // Empty spacer
  { source: BOOKMAKER_CODE_NAMES['TAB VIC'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['TAB NSW'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['UBET'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['UBET'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE }
]

export const UK_COLUMNS = [
  { source: BOOKMAKER_CODE_NAMES['William Hill UK'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Ladbrokes UK'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['BoyleSports'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Coral'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['RaceBets'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['SkyBet'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['BetVictor'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Paddy Power'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Sportsbet UK'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Unibet UK'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['888 Sport'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Betway'], type: BET_TYPES.WIN_FIXED_ODDS, display: COLUMN_TYPE_PRICE }
  // { source: '10B', type: BET_TYPES.WIN_FIXED_ODDS, display: 0 }
]

export const US_COLUMNS = [
  { source: BOOKMAKER_CODE_NAMES['Global Tote'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Global Tote'], type: BET_TYPES.PLACE, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Global Tote'], type: BET_TYPES.SHOW, display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['Global Tote'], type: BET_TYPES.WIN, display: COLUMN_TYPE_FLUCS },
  { source: 'INF-last10Starts', type: 'RUNNER_INFO', selection: 'last_10', title: 'Last 10 Starts', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-trainer', type: 'RUNNER_INFO', selection: 'trainer', title: 'Trainer', display: COLUMN_TYPE_INFO },
  { source: 'INF-formComments', type: 'RUNNER_INFO', selection: 'comment', title: 'Form Comments', display: COLUMN_TYPE_INFO, queryType: 'core' }
]

export const RESULTS_COLUMNS = [
  { source: BOOKMAKER_CODE_NAMES['TAB VIC'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE, label: BETTYPE_WIN },
  { source: BOOKMAKER_CODE_NAMES['TAB NSW'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE, label: BETTYPE_WIN },
  { source: BOOKMAKER_CODE_NAMES['UBET'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE, label: BETTYPE_WIN },
  { source: BOOKMAKER_CODE_NAMES['Official Price'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE, label: BETTYPE_WINFIXEDODDS },
  { source: BOOKMAKER_CODE_NAMES['Betfair'], type: BET_TYPES.WIN, display: COLUMN_TYPE_PRICE, label: BETTYPE_WINFIXEDODDS },
  { source: 'SPC', type: '', display: COLUMN_TYPE_PRICE },
  { source: BOOKMAKER_CODE_NAMES['TAB VIC'], type: BET_TYPES.PLACE, display: COLUMN_TYPE_PRICE, label: BETTYPE_WIN },
  { source: BOOKMAKER_CODE_NAMES['TAB NSW'], type: BET_TYPES.PLACE, display: COLUMN_TYPE_PRICE, label: BETTYPE_WIN },
  { source: BOOKMAKER_CODE_NAMES['UBET'], type: BET_TYPES.PLACE, display: COLUMN_TYPE_PRICE, label: BETTYPE_WIN },
  { source: BOOKMAKER_CODE_NAMES['Betfair'], type: BET_TYPES.PLACE, display: COLUMN_TYPE_PRICE, label: BETTYPE_WINFIXEDODDS }
]

export const COLUMN_TYPE = {
  WIN_FIXED_ODDS: 'price',
  PLACE_FIXED_ODDS: 'price',
  WIN: 'price',
  PLACE: 'price',
  PLACE_2: 'price',
  RUNNER_INFO: 'info',
  RUNNER_HISTORY: 'history',
  'back-1': 'price',
  'back-2': 'price',
  'back-3': 'price',
  'back-amount-1': 'price',
  'back-amount-2': 'price',
  'back-amount-3': 'price',
  'lay-1': 'price',
  'lay-2': 'price',
  'lay-3': 'price',
  'lay-amount-1': 'price',
  'lay-amount-2': 'price',
  'lay-amount-3': 'price',
  'last-matched-price': 'price',
  'projected-sp': 'price',
  'total-matched': 'price',
  'weighted-avg': 'price',
  BEST_PRICE: 'bestPrice',
  BEST_TOTE: 'bestTote',
  CUSTOM_DATA: null
}

export const PRICE_CHANGE_BOOKIES = [
  // { value: BOOKMAKER_CODE_NAMES['BestBookies'] },
  { value: BOOKMAKER_CODE_NAMES['Betdogs'] },
  { value: BOOKMAKER_CODE_NAMES['Betfair'] },
  // { value: BOOKMAKER_CODE_NAMES['Bet Deluxe'] },
  { value: BOOKMAKER_CODE_NAMES['Bet Gold'] },
  { value: BOOKMAKER_CODE_NAMES['BetVictor'] },
  { value: BOOKMAKER_CODE_NAMES['BlueBet'] },
  { value: BOOKMAKER_CODE_NAMES['BoyleSports'] },
  { value: BOOKMAKER_CODE_NAMES['Coral'] },
  { value: BOOKMAKER_CODE_NAMES['ChaseBet'] },
  { value: BOOKMAKER_CODE_NAMES['David Dwyer'] },
  { value: BOOKMAKER_CODE_NAMES['Ladbrokes'] },
  { value: BOOKMAKER_CODE_NAMES['Ladbrokes UK'] },
  { value: BOOKMAKER_CODE_NAMES['Neds'] },
  { value: BOOKMAKER_CODE_NAMES['NZ TAB'] },
  { value: BOOKMAKER_CODE_NAMES['Official Price'] },
  { value: BOOKMAKER_CODE_NAMES['PlayUp'] }, // playup
  { value: BOOKMAKER_CODE_NAMES['RaceBets'] },
  { value: BOOKMAKER_CODE_NAMES['Sportsbet'] },
  { value: BOOKMAKER_CODE_NAMES['BoomBet'] },
  { value: BOOKMAKER_CODE_NAMES['SkyBet'] },
  { value: BOOKMAKER_CODE_NAMES['TABTouch'] },
  { value: BOOKMAKER_CODE_NAMES['TAB Fixed NSW'] },
  { value: BOOKMAKER_CODE_NAMES['TAB Fixed VIC'] },
  { value: BOOKMAKER_CODE_NAMES['TAB Fixed QLD'] },
  { value: BOOKMAKER_CODE_NAMES['TopSport'] },
  { value: BOOKMAKER_CODE_NAMES['UBET'] },
  { value: BOOKMAKER_CODE_NAMES['Unibet'] },
  { value: BOOKMAKER_CODE_NAMES['Paddy Power'] },
  { value: BOOKMAKER_CODE_NAMES['Unibet UK'] },
  { value: BOOKMAKER_CODE_NAMES['888 Sport'] },
  { value: BOOKMAKER_CODE_NAMES['Betway'] },
  { value: BOOKMAKER_CODE_NAMES['WinBet'] },
  { value: BOOKMAKER_CODE_NAMES['SportChamp Racing'] },
  { value: BOOKMAKER_CODE_NAMES['EskanderBet'] }
  // { value: '10B' }
]

export const RUNNER_INFO_COLUMNS = [
  { source: 'INF-age', type: 'RUNNER_INFO', selection: 'age', title: 'Age', display: COLUMN_TYPE_INFO },
  { source: 'INF-api', type: 'RUNNER_HISTORY', selection: 'api', title: 'API (Average Prize Money Index)', display: COLUMN_TYPE_INFO },
  { source: 'INF-barrier', type: 'RUNNER_INFO', selection: 'barrier', title: 'Barrier', display: COLUMN_TYPE_INFO },
  { source: 'INF-barrierScr', type: 'RUNNER_HISTORY', selection: 'barrier_live', title: 'Barrier After Scr', display: COLUMN_TYPE_INFO },
  { source: 'INF-blinkers', type: 'RUNNER_HISTORY', selection: 'blinkers', title: 'Blinkers', display: COLUMN_TYPE_INFO, sub: 'blinkers' },
  { source: 'INF-country', type: 'RUNNER_INFO', selection: 'country', title: 'Country', display: COLUMN_TYPE_INFO },
  { source: 'INF-runnerColor', type: 'RUNNER_INFO', selection: 'colour', title: 'Colour', display: COLUMN_TYPE_INFO },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDO1stup', title: 'Career stats (1st up)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDOAll', title: 'Career stats (All Starts)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDOJockey', title: 'Career stats (Current Jockey)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDODist', title: 'Career stats (Current Distance)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDOTrack', title: 'Career stats (Distance/Track)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDOTrackStats', title: 'Career stats (Track)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDOFast', title: 'Career stats (Fast)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDOGood', title: 'Career stats (Good)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDOHeavy', title: 'Career stats (Heavy)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-stats', type: 'RUNNER_INFO', selection: 'statsDOSlow', title: 'Career stats (Slow)', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-dam', type: 'RUNNER_INFO', selection: 'dam', title: 'Dam', display: COLUMN_TYPE_INFO },
  { source: 'INF-damCountry', type: 'RUNNER_INFO', selection: 'damCountry', title: 'Dam Country', display: COLUMN_TYPE_INFO },
  { source: 'INF-daysSince', type: 'RUNNER_HISTORY', selection: 'dsls', title: 'DSLS (Days Since Last Start)', display: COLUMN_TYPE_INFO },
  { source: 'INF-gear', type: 'RUNNER_HISTORY', selection: 'gear', title: 'Gear', display: COLUMN_TYPE_INFO },
  { source: 'INF-formComments', type: 'RUNNER_INFO', selection: 'comment', title: 'Form Comments', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-jockey', type: 'RUNNER_INFO', selection: 'jockey', title: 'Jockey', display: COLUMN_TYPE_INFO },
  { source: 'INF-allowanceWeight', type: 'RUNNER_INFO', selection: 'allowanceWeight', title: 'Jockey apprentice weight', display: COLUMN_TYPE_INFO },
  { source: 'INF-jockeyColour', type: 'RUNNER_INFO', selection: 'jockey_colours', title: 'Jockey Colours', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-statsYear', type: 'RUNNER_HISTORY', selection: 'jockey_stats_year', title: 'Jockey Stats Year', display: COLUMN_TYPE_INFO },
  { source: 'INF-lastStartBb', type: 'RUNNER_HISTORY', selection: 'last_start_beaten_by', title: 'Last Start BB', display: COLUMN_TYPE_INFO },
  { source: 'INF-last10Starts', type: 'RUNNER_INFO', selection: 'last_10', title: 'Last 10 Starts', display: COLUMN_TYPE_INFO, queryType: 'core' },
  { source: 'INF-last4Starts', type: 'RUNNER_HISTORY', selection: 'last_4', title: 'Last 4 Starts', display: COLUMN_TYPE_INFO },
  { source: 'INF-owners', type: 'RUNNER_INFO', selection: 'owners', title: 'Owners', display: COLUMN_TYPE_INFO },
  { source: 'INF-prizeMoney', type: 'RUNNER_INFO', selection: 'prizeMoney', title: 'Prize Money', display: COLUMN_TYPE_INFO },
  { source: 'INF-number', type: 'RUNNER_INFO', selection: 'tabNo', title: 'Runner No', display: COLUMN_TYPE_INFO },
  { source: 'INF-sex', type: 'RUNNER_INFO', selection: 'sex', title: 'Sex', display: COLUMN_TYPE_INFO },
  { source: 'INF-sire', type: 'RUNNER_INFO', selection: 'sire', title: 'Sire', display: COLUMN_TYPE_INFO },
  { source: 'INF-sireCountry', type: 'RUNNER_INFO', selection: 'sireCountry', title: 'Sire Country', display: COLUMN_TYPE_INFO },
  { source: 'INF-strikeRate', type: 'RUNNER_HISTORY', selection: 'strike_rate_first', title: 'Strikerate % (1st)', display: COLUMN_TYPE_INFO },
  { source: 'INF-strikeRate', type: 'RUNNER_HISTORY', selection: 'strike_rate_second', title: 'Strikerate % (COLUMN_TYPE_INFOnd)', display: COLUMN_TYPE_INFO },
  { source: 'INF-strikeRate', type: 'RUNNER_HISTORY', selection: 'strike_rate_third', title: 'Strikerate % (3rd)', display: COLUMN_TYPE_INFO },
  { source: 'INF-strikeRate', type: 'RUNNER_HISTORY', selection: 'strike_rate_place', title: 'Strikerate % (Place)', display: COLUMN_TYPE_INFO, sub: 'sr_plc' },
  { source: 'INF-trainer', type: 'RUNNER_INFO', selection: 'trainer', title: 'Trainer', display: COLUMN_TYPE_INFO },
  { source: 'INF-statsYear', type: 'RUNNER_HISTORY', selection: 'trainer_stats_year', title: 'Trainer Stats Year', display: COLUMN_TYPE_INFO },
  { source: 'INF-trainerLocation', type: 'RUNNER_HISTORY', selection: 'trainer_location', title: 'Trainer Location', display: COLUMN_TYPE_INFO },
  { source: 'INF-weight', type: 'RUNNER_INFO', selection: 'weightTotal', title: 'Weight', display: COLUMN_TYPE_INFO },
  { source: 'INF-weightAllocated', type: 'RUNNER_INFO', selection: 'weightAllocated', title: 'Weight Allocated', display: COLUMN_TYPE_INFO }
]

export const TOTE_BOOKIES = [
  { source: BOOKMAKER_CODE_NAMES['Global Tote'] },
  { source: BOOKMAKER_CODE_NAMES['TAB NSW'] },
  { source: BOOKMAKER_CODE_NAMES['TAB VIC'] },
  { source: BOOKMAKER_CODE_NAMES['TABTouch'] },
  { source: BOOKMAKER_CODE_NAMES['UBET'] }
]

export const FIRST_TO_OPEN_PRICE_MARKETS = [
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed NSW'] },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed VIC'] },
  { source: BOOKMAKER_CODE_NAMES['TAB Fixed QLD'] },
  { source: BOOKMAKER_CODE_NAMES['Sportsbet'] },
  { source: BOOKMAKER_CODE_NAMES['Ladbrokes'] },
  { source: BOOKMAKER_CODE_NAMES['Neds'] },
  { source: BOOKMAKER_CODE_NAMES['TopSport'] },
  { source: BOOKMAKER_CODE_NAMES['BoomBet'] },
  { source: BOOKMAKER_CODE_NAMES['PlayUp'] }
]

export const DELETED_BOOKIES = [
  { source: BOOKMAKER_CODE_NAMES['WinBet'] }
]
export const DO_SUBSCRIPTION_PRODUCTCODE = 'DO'
export const DF_SUBSCRIPTION_PRODUCTCODE = 'DF'
export const DO_SUBSCRIPTION_LEVELS = [
  { code: 'SUBSCRIPTION_PREMIUM', value: 0 },
  { code: 'SUBSCRIPTION_EXPERT', value: 1 },
  { code: 'SUBSCRIPTION_STANDARD', value: 2 },
  { code: 'SUBSCRIPTION_PLUS', value: 3 },
  { code: 'SUBSCRIPTION_BASIC_WITH_DYNAMICFORM', value: 4 },
  { code: 'SUBSCRIPTION_STANDARD_WITH_DYNAMICFORM', value: 7 },
  { code: 'SUBSCRIPTION_PLUS_WITH_DYNAMICFORM', value: 8 }
]

export const AU_DEFAULT_COLUMNS = [
  { name: 'AU', editMode: false },
  { name: 'UK', editMode: false },
  { name: 'A', editMode: true },
  { name: 'B', editMode: true },
  { name: 'C', editMode: true },
  { name: 'D', editMode: true },
  { name: 'E', editMode: true }
]

export const GTX_DEFAULT_COLUMNS = [
  { name: 'US', editMode: false },
  { name: 'A', editMode: true },
  { name: 'B', editMode: true },
  { name: 'C', editMode: true },
  { name: 'D', editMode: true },
  { name: 'E', editMode: true }
]

export const AU_PRICE_MENU_ITEMS = [
  {
    type: 'tote',
    title: 'Tote',
    items: TOTE_BOOKIES
  },
  {
    type: 'fixed',
    title: 'Fixed',
    items: [
      { source: BOOKMAKER_CODE_NAMES['BaggyBet'] },
      { source: BOOKMAKER_CODE_NAMES['SportChamp Racing'] },
      { source: BOOKMAKER_CODE_NAMES['EskanderBet'] },
      { source: 'BB4' },
      // { source: 'BB2' },
      { source: 'BD' },
      // { source: 'BT' },
      // { source: 'BD2' },
      { source: 'BV' },
      { source: 'BB3' },
      { source: 'BG' },
      { source: 'BS3' },
      { source: BOOKMAKER_CODE_NAMES['BoomBet'] },
      { source: 'BW2' },
      { source: 'CHB' },
      { source: 'CB3' },
      { source: 'DD' },
      { source: 'GSB' },
      { source: 'LB2' },
      { source: 'LB2U' },
      { source: 'ND' },
      { source: 'NZ' },
      { source: 'PP2' },
      { source: 'OP' },
      { source: 'CB2' }, // playup
      { source: 'RB2' },
      { source: 'RB3' },
      { source: BOOKMAKER_CODE_NAMES['Sportsbet'] },
      { source: 'SBU' },
      { source: 'SB6' },
      { source: 'OB' },
      { source: BOOKMAKER_CODE_NAMES['TAB Fixed NSW'] },
      { source: BOOKMAKER_CODE_NAMES['TAB Fixed VIC'] },
      { source: BOOKMAKER_CODE_NAMES['TAB Fixed QLD'] },
      { source: 'TS2' },
      { source: 'Q' },
      { source: 'UB' },
      { source: 'UB_U' },
      { source: '888' },
      { source: 'VPB' },
      { source: 'WH' },
      { source: 'WB3' }
    ]
  }
]

export const US_PRICE_MENU_ITEMS = [
  {
    type: 'tote',
    title: 'Tote',
    items: [{ source: BOOKMAKER_CODE_NAMES['Global Tote'] }]
  }
]
