<template>
  <v-dialog v-if="!useNewSubModule" v-model="userSubscription" persistent width="500">
    <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title v-if="noDynamicForm">DynamicForm subscription</v-toolbar-title>
          <v-toolbar-title v-else>Enter Your payment details </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="cancelButton" icon small @click="closePayment" v-if="noDynamicForm" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </v-toolbar>
        <div v-if="noDynamicForm">
            <div class="subtitle-1 pa-3">Upgrade subscription to access <v-img class="mt-1" src="@/assets/fa-logo.png" width="200"></v-img></div>
            <!--<a @click="openDynamicForm()"><v-card-text class="pt-0 pl-3">Continue to Free Trial of DynamicForm</v-card-text></a>-->
        </div>
        <div class="contentDiv">
          <v-list three-line subheader>
            <iframe
              v-if="!noDynamicForm"
              id="inlineFrameExample"
              title="Inline Frame Example"
              width="320"
              height="510"
              :src="getUrl"
              frameBorder="0"
              class="iframe pa-2">
            </iframe>
            <iframe
              v-if="noDynamicForm"
              id="inlineFrameExample"
              title="Inline Frame Example"
              width="320"
              height="510"
              :src="getUrlDf"
              frameBorder="0"
              class="iframe pa-2">
            </iframe>
          </v-list>
          <div class="sideContent">
            <v-list class="sideContentButtons">
                <v-list-item v-if="!noDynamicForm" class="menuItem">
                  <v-btn dark @click="logout()" color="error">
                    <span>Logout</span>
                  </v-btn>
                </v-list-item>
                <v-list-item class="menuItem">
                  <Support />
                </v-list-item>
              </v-list>
          </div>
        </div>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Support from './Support'

export default {
  props: {
    userSubscription: Boolean,
    noDynamicForm: Boolean,
    closePayment: Function
  },
  components: {
    Support
  },
  data () {
    return {
      interval: null
    }
  },
  computed: {
    ...mapGetters('account', ['getMemberId']),
    ...mapGetters(['hasFeature']),
    ...mapState('account', ['authorized', 'user']),
    getUrl: function () {
      return `https://old.dynamicodds.com/new/pages/purchase3.asp?p_id=3&currency=AUD&m_id=${this.getMemberId}&recurring=1`
    },
    getUrlDf: function () {
      return `https://old.dynamicodds.com/new/pages/purchase3.asp?p_id=35&currency=AUD&m_id=${this.getMemberId}&recurring=1`
    },
    useNewSubModule () {
      return this.hasFeature('newSubscription')
    }
  },
  methods: {
    ...mapActions('account', ['logout', 'fetchUserAccountStatus'])
    // async openDynamicForm () {
    //   const username = this.user.username
    //   const email = this.user.attributes.email
    //   if (process.env.VUE_APP_ENV === 'production') {
    //     window.open(`https://app.dynamicform.com.au/?open=freetrial&u_name=${username}&email=${email}`)
    //   } else {
    //     window.open(`https://formanalyzer.gcpuatau.tbm.sh/?open=freetrial&u_name=${username}&email=${email}`)
    //   }
    // }
  }
  // mounted () {
  //   const interval = setInterval(() => {
  //     this.fetchUserAccountStatus()
  //   }, 2000)
  //   this.interval = interval
  // },
  // beforeDestroy () {
  //   clearInterval(this.interval)
  // }
}
</script>

<style scoped>
.contentDiv {
  display: flex;
}
.sideContent {
  width: 200px;
}
.sideContentButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menuButton {
  padding: 10px;
  margin-right: 20px;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  font-size: 16px;
}
.iframe {
  overflow: hidden;
}
.username {
  margin-top: 20px;
  margin-bottom: 15px;
}
</style>
