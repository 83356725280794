<template>
  <div style="z-index:0;position:fixed;width:100%;">
    <Payment v-if="paymentDialog" :userSubscription="paymentDialog" :noDynamicForm="usersubDf" :closePayment="closePayment"/>
    <v-app-bar app height="35" elevation="0" :class="`box-shadow--container ${isAutoHide ? 'navClosed' : ''}`" color="transparent" :clipped-left="isAutoHide">
      <v-img src="@/assets/64_do.png" :height="logoHeight" :max-width="logoWidth" contain position="left"></v-img>
      <div v-if="urlLocation" style="transform: scale(0.8); position: relative; top: 3px;">
        <country-flag :country="countrySet(urlLocation)" size="normal"/>
      </div>
      <UserProfile v-if="hasFeature('userProfile')" />
      <div v-else class="username" style="width: 150px"><v-icon>mdi-account</v-icon> <span>{{getUsername}}</span> </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
<!--        <v-btn style="background-color: rgba(26, 35, 126, 0.1)" class="pa-0 px-1 btn px-4" elevation="0" @click="openDynamicForm" tile v-if="hasFeature('formAnalyser')">-->
<!--          <img src="@/assets/fa-logo.png" width="113" />-->
<!--        </v-btn>-->
        <v-dialog v-if="hasFeature('bookieSignup')"
          v-model="bookieSignup"
          persistent
          max-width="800"
        >
          <template v-slot:activator="{ on, bookie }">
            <v-btn
              text
              v-bind="bookie"
              v-on="on"
            >
              Bookie SignUp
          <v-icon small class="ml-2" color="orange">mdi-form-select</v-icon>
            </v-btn>
          </template>
          <v-card class="pt-5">
            <iframe frameBorder="0" id="statushub-widget" style="width:760px" src="http://bookiesignup.betmakers.com.s3-website-ap-southeast-2.amazonaws.com/" width="950" height="750"></iframe>
            <v-card-text class="warning--text">Please note: Once signed up you will have to manually login to each bookmaker in the DynamicOdds Platform</v-card-text>
            <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="bookieSignup = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="425"
        >
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              text
              v-bind="attrs"
              v-on="on"
              style="font-size: 12px;"
              class="pa-0 px-1"
            >
              <v-icon small class="mr-1" color="orange">mdi-lifebuoy</v-icon>
              <span>Support</span>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
              Support <v-icon class="ml-2" color="red">mdi-lifebuoy</v-icon>
            </v-card-title>
            <v-card-text>Here you can find useful FAQ's, Tutorials, and our Service Status if you can't find what you're looking for please submit a request</v-card-text>
            <iframe frameBorder="0" id="statushub-widget" style="width:425px;" src="https://cdn.statushub.io/apps/widget/3.4.1/app/iframe.html?token=8ec5a356e1d5d2619e40f131e047d010333a8fbe&daysBefore=30&daysAfter=30&limit=10&incidentsFirst=false" width="400px" height="500px"></iframe>
            <v-card-text><b>Need help?</b> Submit a Support Request below (via registered support account) or email <a class="blue--text" href="mailto:support@dynamicodds.com">support@dynamicodds.com</a></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text href="https://betmakers.service-now.com/b2c-portal?id=kb_search&kb_knowledge_base=2b292926c3302200e7c7d44d81d3ae10 " target="_blank">
                FAQ's &amp; Tutorials
              </v-btn>
              <v-btn text href="https://betmakers.service-now.com/b2c-portal" target="_blank">
                Support Request
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-if="hasFeature('raceDayUpdates')" text @click="showTwitterFeed();" style="font-size: 12px;" class="pa-0 px-1 mr-1">
          <v-icon small class="ml-1" color="orange">mdi-book</v-icon>
          <span>Steward Updates</span>
        </v-btn>
        <BetHistory v-if="hasFeature('betHistory')" />
        <v-tooltip v-if="!hasFeature('betHistory')" bottom color="error" style="z-index:202; position:relative">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="clickFeature" v-bind="attrs" v-on="on" class="pa-0 px-1 mr-3 btn">
              <v-icon class="mr-2" small>mdi-history</v-icon>
              Bet History
            </v-btn>
          </template>
          <span>Not yet available in BETA</span>
        </v-tooltip>

        <v-btn @click="changeAcMode" class="primary ml-n2 mr-2 btn" text>
          <v-icon v-if="!acMode" class="mr-2" small>mdi-eye-minus-outline</v-icon>
          <v-icon v-if="acMode" class="mr-2" small>mdi-eye-check-outline</v-icon>
          <span style="font-size: 12px;">High Contrast</span>
        </v-btn>
        <!--<v-btn v-if="fullscreen" @click="fullScreenMode" text>Full Screen <v-icon>mdi-fullscreen-exit</v-icon></v-btn>
        <v-btn v-else @click="fullScreenMode" text>Full Screen <v-icon>mdi-fullscreen</v-icon></v-btn>-->
        <v-btn class="navigation-signin" v-if="!authorized" to="/login" color="success">SIGN IN</v-btn>
        <template v-if="hasFeature('signUpAvailable')">
          <v-btn v-if="!authorized" color="blue" to="/signup">JOIN</v-btn>
        </template>

        <v-btn class="navigation-signout btn" v-if="authorized" @click="logout" color="red darken-3" style="color:#fff;">
          <v-icon class="mr-2" small>mdi-logout</v-icon>
          <span style="font-size: 12px;">SIGN OUT</span>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import BetHistory from '../components/BetHistory'
import UserProfile from '../components/UserProfile'
import Payment from '../components/Payment'
// import { DF_SUBSCRIPTION_PRODUCTCODE } from '../common/constants'
// import { getUserNewSubscriptionInfo, getUserSubStatusLegacy } from '../services/userAccount'
export default {
  name: 'AppNavigation',
  props: {
    clickFeature: Function,
    fullScreenMode: Function,
    fullscreen: Boolean,
    changeAcMode: Function,
    acMode: Boolean
  },
  data () {
    return {
      dialog: false,
      bookieSignup: false,
      paymentDialog: false,
      usersubDf: true,
      twitterModal: false
    }
  },
  components: {
    BetHistory,
    UserProfile,
    Payment
  },
  computed: {
    ...mapState('account', ['authorized', 'userSubscription']),
    ...mapState(['urlLocation', 'isAutoHide']),
    ...mapGetters(['hasFeature']),
    ...mapGetters('account', ['getUsername']),
    userSub () {
      if (this.hasFeature('userSubscriptions')) {
        return this.userSubscription
      }
      return true
    },
    logoHeight () {
      return process.env.VUE_APP_LOGO_HEIGHT || '25'
    },
    logoWidth () {
      return process.env.VUE_APP_LOGO_WIDTH || '80'
    }
  },
  methods: {
    ...mapActions('account', ['logout', 'getSubscriptionLevel']),
    countrySet (country) {
      if (country) {
        switch (country) {
          case 'Local':
            return 'AU'
        }
      } return country
    },
    closePayment () {
      this.paymentDialog = false
    },
    // async openDynamicForm () {
    //   let hasDFSub = false
    //   // check if has DF sub in new system, yes then open DF
    //   if (this.hasFeature('newSubscription')) {
    //     const userSubInfo = await getUserNewSubscriptionInfo(DF_SUBSCRIPTION_PRODUCTCODE)
    //     hasDFSub = userSubInfo.subStatus === 1
    //   }
    //   // no sub in new system, then check legacy is legacy still enabled
    //   if (!hasDFSub && this.hasFeature('legacySubscription')) {
    //     const legacySub = await getUserSubStatusLegacy(window.doApp.$store.getters.authConfig)
    //     hasDFSub = legacySub?.data?.subscription_level === 7
    //   }
    //   if (hasDFSub) { // if has df sub in either new or legacy system, then open DF
    //     window.open(process.env.VUE_APP_DYNAMIC_FORM_UI_URL)
    //   } else {
    //     // if no DF sub, then prompt user to subscribe in new sub system
    //       await window.doApp.$store.dispatch('account/updateAuthToken')
    //       const token = window.localStorage.getItem('auth_token')
    //       const paramObj = {
    //         userToken: token,
    //         email: window.doApp.$store.getters['account/getUser'].attributes.email,
    //         productCode: DF_SUBSCRIPTION_PRODUCTCODE,
    //         memberId: window.doApp.$store.getters['account/getMemberId'],
    //         userName: window.doApp.$store.getters['account/getUsername'],
    //         isUpdatingCreditCard: false,
    //         callbackObj: {
    //           success: () => { window.open(process.env.VUE_APP_DYNAMIC_FORM_UI_URL) },
    //           cancel: () => { },
    //           cancelText: 'Cancel'
    //         }
    //       }
    //       window.__tbm__widget__sub.exposed.bootsTrap(paramObj)
    //     }
    //     // this.paymentDialog = true
    //     // this.usersubDf = true
    // },
    showTwitterFeed () {
      window.open('https://twitter.com/i/lists/1633647053019697153', 'popup', 'width=600,height=600')
      // replace when twitter api is fixed
      // this.$modal.show('twitter-modal')
    }
  }
}
</script>

<style>
  .btn.v-btn{
    border-radius: 100px!important;
    height: 25px !important;
    margin-top: 5px;
  }

  .username {
    cursor: pointer;
  }

  .navClosed {
    margin-left: 32px;
    margin-bottom: 200px;
  }
</style>
